* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

.slick-slide div {
  outline: none;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: rgb(138, 138, 138);
}

::-webkit-scrollbar-thumb {
  background-color: #244c64;
  border-radius: 1px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #213d4e;
}

img {
  -webkit-user-drag: none;
}
